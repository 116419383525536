import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/Content"


const ResumePage = () => (
  <Layout>
    <SEO title="Resume" />
    <Content flexCenter={false}>

      <h1>Niko Schmidt</h1>
      <p>
        Seitdem ich mit 12 mit einem Computer zur Welt kam, bin ich fasziniert von all den Möglichkeiten der digitalen Welt <span className="orange">// </span>
        Ein Jahr später hab ich meine erste Website erstellt – im Website-Baukasten "<a target="_blank" href="https://de.wikipedia.org/wiki/IWeb">iWeb</a>" vom damaligen OSX <span className="orange">// </span>
        <br></br>
        <br></br>
        <span className="orange">....</span> War super stolz auf Hover Effekte, das Switchen eines selbst designten Icons – das war der Hammer!!
        Diese erste Seite publizierte ich über den damaligen <a target="_blank" href="https://help.dropbox.com/de-de/files-folders/share/public-folder">"Public"-Ordner</a> in Dropbox und machte ihn über eine kostenloste .TK Domain aufrufbar <span className="orange">//</span>
        <br></br>
        <br></br>
        <span className="orange">....</span> Über diesen Weg lernte ich das Internet kennen <span className="orange">//</span> YouTube war natürlich ganz große Klasse und animierte mich dazu kleine <a target="_blank" href="https://de.wikipedia.org/wiki/Stop-Motion">Stop-Motion Filme</a> zu erstellen <span className="orange">//</span>
        <br></br>
        <br></br>
        <span className="orange">.... </span> Seitdem sind viele Tassen Kaffee getrunken worden <span className="orange">.... </span><br></br><br></br>
        <span className="orange">....</span> Immer auf dem neusten Stand zu bleiben und moderne Designtrends mitzunehmen macht mir sehr viel Spaß <span className="orange">//</span>
        <br></br>
        <br></br>

      </p>

    </Content>
  </Layout>
)

export default ResumePage
